export default {
  methods: {
    dialog(state) {
      return this.dialogState[state];
    },
    open(state, item) {
      this.dialogState[state] = true;

      if (item) {
        this.getLubeDetails(item.id);
      }
    },
    close(state) {
      this.dialogState[state] = false;
    },
    cancel() {
      this.$store.commit("UPDATE_SNACKBAR", false, { root: true });
    }
  }
};
