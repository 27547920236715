var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row flex-wrap flex-grow-1 flex-shrink-1",staticStyle:{"width":"100%"},attrs:{"id":"dashboard"}},[(!_vm.internetState)?_c('div',{staticClass:"d-flex flex-row flex-wrap",staticStyle:{"width":"100%"}},[(_vm.pageLoading)?_c('div',{staticClass:"d-flex flex-row flex-wrap justify-center mt-4",staticStyle:{"width":"100%"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"width":"100%","height":"calc(100vh - 60px)"}},[_c('div',{staticStyle:{"min-width":"408px","overflow-y":"auto","padding":"20px","flex":"8 1 0%"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1 flex-wrap"},[_c('v-card',{staticClass:"box-shadow-light d-flex flex-row flex-wrap flex-grow-1 pa-3 ma-1"},[_c('div',{staticClass:"d-flex flex-column"},[_c('v-avatar',{attrs:{"color":"rgba(9, 55, 255, 0.21)","size":"60"}},[_c('i',{staticClass:"material-icons-outlined",staticStyle:{"color":"#0937ff !important"}},[_vm._v("shopping_basket")])])],1),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row flex-full flex-wrap"},[_c('h4',{staticClass:"ft font-weight-medium mx-3 mt-1 grey--text"},[_vm._v(" Total Suppliers ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"vc--verified-icon",attrs:{"small":"","color":"#09c711"}},on),[_vm._v("verified_user")])]}}],null,false,1400575519)},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Verified Suppliers")])])],1),_c('div',{staticClass:"d-flex flex-row flex-full flex-wrap"},[_c('span',{staticClass:"ft font-size-lg font-weight-bold ml-3"},[_vm._v(_vm._s(_vm.totals.totalSuppliers))])])])]),_c('v-card',{staticClass:"box-shadow-light d-flex flex-row flex-wrap flex-grow-1 pa-3 ma-1"},[_c('div',{staticClass:"d-flex flex-column"},[_c('v-avatar',{attrs:{"color":"rgba(227, 77, 254, 0.22)","size":"60"}},[_c('i',{staticClass:"material-icons-outlined",staticStyle:{"color":"#af06e4 !important"}},[_vm._v("sanitizer")])])],1),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row flex-full flex-wrap"},[_c('h4',{staticClass:"font font-weight-medium mx-3 mt-1 grey--text"},[_vm._v(" Total Lubes ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"vc--verified-icon",attrs:{"small":"","color":"#09c711"}},on),[_vm._v("verified_user")])]}}],null,false,1400575519)},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("All Lubes")])])],1),_c('div',{staticClass:"d-flex flex-row flex-full flex-wrap"},[_c('span',{staticClass:"ft font-weight-bold font-size-lg text-lg ml-3"},[_vm._v(_vm._s(_vm.totals.totalLubes))])])])]),_c('v-card',{staticClass:"box-shadow-light d-flex flex-row flex-wrap flex-grow-1 pa-3 ma-1"},[_c('div',{staticClass:"d-flex flex-column"},[_c('v-avatar',{attrs:{"color":"rgba(255, 152, 0, 0.19)","size":"60"}},[_c('i',{staticClass:"material-icons-outlined",staticStyle:{"color":"rgb(255, 152, 0)"}},[_vm._v("people")])])],1),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row flex-full flex-wrap"},[_c('h4',{staticClass:"ft font-weight-medium mx-3 mt-1 grey--text"},[_vm._v(" Total Customers ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"vc--verified-icon",attrs:{"small":"","color":"#09c711"}},on),[_vm._v("verified_user")])]}}],null,false,1400575519)},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Verified Customers")])])],1),_c('div',{staticClass:"d-flex flex-row flex-full flex-wrap"},[_c('span',{staticClass:"ft font-weight-bold font-size-lg  ml-3"},[_vm._v(_vm._s(_vm.totals.totalCustomers))])])])])],1),_c('div',{staticClass:"d-flex flex-row my-2 mt-6",staticStyle:{"justify-content":"space-between"}},[_c('h4',{staticClass:"ft font-weight-medium font-size-lg"},[_vm._v(" Daily Sales Reports "),(_vm.startDate && _vm.endDate && _vm.branchId)?_c('span',[_vm._v("/("+_vm._s(_vm._f("timeFormat")(_vm.startDate,"Do MMMM, YYYY"))+" - "+_vm._s(_vm._f("timeFormat")(_vm.endDate,"Do MMMM, YYYY"))+")")]):_vm._e()])]),_c('div',{staticClass:"d-flex flex-row"},[_c('v-autocomplete',{staticClass:"ft font-weight-medium pr-10 font-size-sm",attrs:{"items":_vm.branches,"item-text":"name","item-value":"id","dense":"","outlined":"","label":"Choose a branch"},on:{"focus":function($event){return _vm.$store.dispatch('branches/list', { isPaginated: false })}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:" ft font-weight-medium font-size-sm"},[_vm._v(_vm._s(item.name)+" ")])]}}],null,false,3000837508),model:{value:(_vm.branchId),callback:function ($$v) {_vm.branchId=$$v},expression:"branchId"}}),_c('div',{staticClass:"font font-weight-medium font-size-md"},[_vm._v(" Start Date: "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.startDate),expression:"startDate"}],staticClass:"box-shadow-light pa-1 ml-2 mr-2",attrs:{"type":"date","placeholder":"yyyy-mm-dd"},domProps:{"value":(_vm.startDate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.startDate=$event.target.value}}}),_vm._v(" End Date: "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.endDate),expression:"endDate"}],staticClass:"box-shadow-light pa-1 ml-2",attrs:{"placeholder":"yyyy-mm-dd","type":"date"},domProps:{"value":(_vm.endDate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.endDate=$event.target.value}}})])],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1 flex-wrap"},[_c('v-card',{staticClass:"box-shadow-light flex-grow-1 d-flex flex-row py-2 rounded-sm",attrs:{"loading":_vm.subLoading}},[_c('apexcharts',{staticClass:"d-flex",staticStyle:{"width":"100% !important"},attrs:{"height":"500","type":"bar","options":_vm.chartOptions,"series":_vm.fuelSalesSeries}})],1)],1),(_vm.startDate && _vm.endDate && _vm.branchId)?_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-shrink-1 flex-wrap  mt-4"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1",staticStyle:{"justify-content":"flex-end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"primary"},on:{"click":_vm.openPDF}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("file_download")])])]}}],null,false,309442111)},[_c('span',{staticClass:" ft font-weight-medium font-size-sm"},[_vm._v(" Save as Pdf ")])])],1),_c('div',{staticClass:"d-flex flex-column flex-grow-1",attrs:{"id":"salesContainer"}},[_c('h4',[_vm._v("Fuel Sales")]),_c('v-simple-table',{staticClass:" ft box-shadow-light",staticStyle:{"width":"100%"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Date ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Total Amount Sold ")])])]),_c('tbody',[(
                      _vm.sales && _vm.sales.fuelSales && _vm.sales.fuelSales.length > 0
                    )?_vm._l((_vm.sales.fuelSales),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(" "+_vm._s(_vm._f("timeFormat")(item.salesDate,"Do MMMM,YYYY"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.totalAmountSold, _vm.currency && _vm.currency.length > 0 ? _vm.currency[0].code : "GHS" ))+" ")])])}):[_c('tr',[_c('td',[_vm._v("No sales were recorded at this time")])])]],2)]},proxy:true}],null,false,3640157946)}),_c('h4',{staticClass:"mt-3"},[_vm._v("Lube Sales")]),_c('v-simple-table',{staticClass:" ft box-shadow-light",staticStyle:{"width":"100%"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Date ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Total Amount Sold ")]),_c('th',[_vm._v(" Total Qty Sold ")])])]),_c('tbody',[(
                      _vm.sales && _vm.sales.lubeSales && _vm.sales.lubeSales.length > 0
                    )?_vm._l((_vm.sales.lubeSales),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s(_vm._f("timeFormat")(item.date,"Do MMMM,YYYY")))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.total, _vm.currency && _vm.currency.length > 0 ? _vm.currency[0].code : "GHS" ))+" ")]),_c('td',[_vm._v(_vm._s(item.totalSold))])])}):[_c('tr',[_c('td',[_vm._v("No sales were recorded at this time")])])]],2)]},proxy:true}],null,false,1212595752)}),_c('h4',{staticClass:"mt-3"},[_vm._v("Shop Sales")]),_c('v-simple-table',{staticClass:" ft box-shadow-light",staticStyle:{"width":"100%"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Date ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Sales Amount ")]),_c('th',{staticClass:"text-left"},[_vm._v(" MD's Allocations ")])])]),_c('tbody',[(
                      _vm.sales && _vm.sales.shopSales && _vm.sales.shopSales.length > 0
                    )?_vm._l((_vm.sales.shopSales),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(_vm._f("timeFormat")(item.salesDate,"Do MMMM,YYYY"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.salesAmount, _vm.currency && _vm.currency.length > 0 ? _vm.currency[0].code : "GHS" ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.mdsAllocationAmount, _vm.currency && _vm.currency.length > 0 ? _vm.currency[0].code : "GHS" ))+" ")])])}):[_c('tr',[_c('td',[_vm._v("No sales were recorded at this time")])])]],2)]},proxy:true}],null,false,2165899741)})],1)]):_vm._e()]),_c('div',{staticClass:"details-sidebar--drawer-bg",staticStyle:{"width":"400px","box-sizing":"border-box","height":"100%","overflow-y":"auto","word-break":"break-word"}},[(!_vm.isNotificationTurnedOn)?_c('div',{staticClass:"d-flex flex-row flex-grow-1  justify-center  mt-10"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 notification-container align-center"},[_c('img',{staticStyle:{"width":"120px"},attrs:{"src":require("@/assets/notification.svg")}}),_c('p',{staticClass:"ft font-weight-bold font-size-lg mx-0"},[_vm._v(" Turn On Notifications ")]),_c('p',{staticClass:"ft font-weight-medium font-size-sm text-center px-5"},[_vm._v(" Receive notifications on activities that takes place on the system ")]),_c('p',[_c('v-btn',{staticClass:"ft font-weight-medium",attrs:{"rounded":"","depressed":"","color":"primary"},on:{"click":_vm.turnOnNotification}},[_vm._v(" Turn On ")])],1)])]):[(_vm.notifications.length > 0)?_c('div',{staticClass:"mt-2 ",staticStyle:{"border-radius":"5px"},attrs:{"flat":""}},[_vm._m(0),_vm._l((_vm.notifications),function(notification){return _c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],key:notification.id,staticClass:"ft d-flex flex-row mx-1 mt-1",class:notification.unread ? 'box-shadow-light' : '',style:({
                background: notification.unread ? 'white' : '#f3f3f3',
                cursor: 'pointer'
              }),on:{"click":function($event){return _vm.markAsRead(notification)}}},[_c('div',{staticClass:"d-flex flex-column pa-3 ma-1"},[_c('v-avatar',{attrs:{"size":"36","color":"primary"}},[_c('span',{staticClass:"ft font-weight-bold white--text"},[_vm._v(_vm._s(notification.event.charAt(0)))])])],1),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mt-3"},[_c('p',{staticClass:"ft font-size-sm px-1",class:notification.unread
                      ? 'font-weight-bold'
                      : 'font-weight-medium grey--text text--darken-3'},[_vm._v(" "+_vm._s(notification.payload)+" "),(notification.userId)?_c('v-chip',{staticClass:"ml-3",attrs:{"label":"","small":"","color":"primary"}},[_vm._v("By "+_vm._s(notification.userId.name))]):_vm._e()],1)]),(notification.unread)?_c('div',{staticClass:"d-flex flex-column pb-2 pr-2",staticStyle:{"justify-content":"flex-end"}},[_c('span',{staticStyle:{"width":"10px","height":"10px","border-radius":"10px","background":"red"}})]):_vm._e()])})],2):_c('div',{staticClass:" white mt-3 ",staticStyle:{"border-radius":"5px"},attrs:{"flat":""}},[_vm._m(1),_c('EmptyPage',{attrs:{"image":require("@/assets/notification.svg"),"title":'No notification right now',"imageWidth":'120px',"subTitle":_vm.placeholderText}})],1)]],2)])]):_vm._e(),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-shadow-light mx-1 title dash-side-container",staticStyle:{"border-radius":"5px"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 ft font-size-lg font-weight-medium ml-1"},[_c('i',{staticClass:"material-icons-outlined mt-1 mr-3"},[_vm._v("notifications")]),_c('span',{staticClass:"ft font-size-md"},[_vm._v("Activities And Notifications")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-shadow-light title dash-side-container"},[_c('h3',{staticClass:"ft font-weight-medium text-md ml-1"},[_vm._v(" All activities And Notifications ")])])}]

export { render, staticRenderFns }